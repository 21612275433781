<template>
  <div class="flix-parent-container">
    <div class="flix-container flix-form-group">
      <div class="flix-row">
        <div class="flix-col-md-4 flix-col-md-flix-offset-4 flix-form-group">
          <div class="flix-well flix-text-center">
            <div class="flix-form-group">
              <div class="flix-html-h1" >
                <flixIcon :id="'lock'"/>
              </div>
              <div class="flix-html-h2">
                {{ $t('message.signIn') }}
              </div>
              <p>{{ $t('message.checkIn') }} / {{ $t('message.checkOut') }}</p>
            </div>
            <form @submit.prevent="getData()" :class="{'flix-error': error}">
              <div class="flix-form-group">
                <input :readonly="prefill === true" type="text" autocomplete="username" v-model="username" class="flix-text-center flix-form-control" spellcheck="false" :placeholder="$t('message.add', { name: $t('message.contact') })"/>
              </div>
              <transition name="flixFadeIn">
              <div class="flix-form-group" v-if="username">
                <input autocomplete="current-password" type="password" v-model="pass" class="flix-text-center flix-form-control" spellcheck="false" :placeholder="$t('message.password')"/>
              </div>
              </transition>
              <transition name="flixFadeIn">
                <div class="flix-form-group" v-if="username && pass">
                  <button type="submit" class="flix-btn flix-btn-block flix-btn-default">{{ $t("message.signIn") }}</button>
                </div>
              </transition>
            </form>
          </div>
        </div>
        <div class="flix-col-md-2 flix-col-md-flix-offset-5 flix-col-xs-12">
          <div class="flix-col-md-8 flix-col-md-flix-offset-2 flix-col-xs-6 flix-col-xs-flix-offset-3">
            <logo class="logo"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CheckIn from './CheckIn'
import PasswordGen from '@/components/checkIn/settings/passwordGen'
export default {
  components: {},
  props: {
    callback: Function
  },
  data () {
    return {
      username: '',
      pass: '',
      error: false,
      prefill: false,
      variables: this.$getUserVariables()
    }
  },
  computed: {

  },
  mounted () {
    this.getUsername()
    this.getAutoLogin()
  },
  methods: {
    getAutoLogin () {
      if (this.prefill === false || typeof this.variables === 'undefined' || typeof this.variables.user === 'undefined') {
        return false
      }
      var u = this.username.split('-')
      var c = new CheckIn()
      c.user = c.getMD5(u[0])
      c.ID = c.getMD5(u[1])
      if (this.variables.user.md5_id !== c.user) {
        return false
      }
      c.callback = function (ret) {
        var pw = new PasswordGen()
        pw.salt = this.variables.user.md5_id
        pw.calendar = ret.form
        this.pass = pw.generatePassword()
      }.bind(this)
      c.getData()
    },
    getUsername () {
      if (this.$route.params.id === 'index.html' || !this.$route.params.id) {
        return false
      }
      this.username = this.$route.params.id
      this.username = this.username.replace('.html', '')
      this.prefill = true
    },
    getData () {
      this.error = false
      var u = this.username.split('-')
      var c = new CheckIn()
      c.user = c.getMD5(u[0])
      c.ID = c.getMD5(u[1])
      c.callback = function (ret) { this.checkPassword(ret) }.bind(this)
      c.getData()
    },
    checkPassword (ret) {
      var pw = new PasswordGen()
      pw.salt = JSON.parse(ret.atitle).user
      pw.calendar = ret.form
      var calPass = pw.generatePassword()
      if (calPass.trim().toLowerCase() === this.pass.trim().toLowerCase()) {
        this.callback(ret)
      } else {
        this.error = true
      }
    }
  }
}
</script>
<style lang="sass" scoped>
  .flix-parent-container
    display: flex
    align-items: center
    min-height: 100vh
  .flix-error
    animation: 0.8s ferror ease-in-out

  @keyframes ferror
    10%, 90%
      transform: translate3d(-2px, 0, 0)

    20%, 80%
      transform: translate3d(4px, 0, 0)

    30%, 50%, 70%
      transform: translate3d(-10px, 0, 0)

    40%, 60%
      transform: translate3d(10px, 0, 0)
</style>
