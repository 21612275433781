import Vue from 'vue'
const md5 = require('js-md5')

const CheckIn = function () {
  this.user = ''
  this.ID = ''
  this.callback = function () {}

  this.getMD5 = function (data) {
    return md5(data + '')
  }

  this.getData = function () {
    Vue.prototype.$flix_post({
      url: 'booking/get_by_id',
      data: {
        user: this.user,
        ID: this.ID
      },
      callback: function (ret) {
        if (ret.data[0]) {
          this.data = ret.data[1][0]
          this.callback(this.data)
        } else {
          // this.error = true
          // this.$router.push('/')
        }
      }.bind(this)
    })
  }
}

export default CheckIn
