const Password = function () {
  this.calendar = false
  this.salt = false

  this.generatePassword = function () {
    var pw = this.calendar
    pw += this.salt
    pw = btoa(pw)

    pw = pw[1] + pw[pw.length - 5] + pw[pw.length - 8] + pw[5] + pw[12] + pw[9] + this.calendar[5] + this.calendar[6]
    pw = pw.toLowerCase()

    return pw
  }
}

export default Password
